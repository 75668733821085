/**
 * Only one popup modal form can exists at the same time
 * @type {null}
 */
let PopupFormModalInstance = null;

/**
 * Submit button available data properties:
 * data-confirmationtitle | confirmation box title, if exists confirmation will be displayed
 * data-confirmationmessage | confirmation box additional message
 * data-confirmationtype | [confirm,alert] | default confirm
 * data-confirmationyes | yes button text
 * data-confirmationno | no button text
 *
 */
function AjaxSubmitPopupFormConfig(confirmTitle, confirmMessage, confirmButtonText, cancelButtonText){
  this.confirmTitle = confirmTitle;
  this.confirmMessage = confirmMessage;
  this.yesText = confirmButtonText;
  this.noText = cancelButtonText;

  this.hasConfirmation = () => !!this.confirmMessage;

  this.getTitle = () => this.confirmTitle ? this.confirmTitle : 'Are you sure?';

  this.getYesText = () => this.yesText ? this.yesText : 'Ok';

  this.getNoText = () => this.noText ? this.noText : 'Cancel';
}

/**
 * Ajax modal form trigger button available data properties
 * data-callbacks | list of js callbacks to execute after ajax defined as comma separated
 * data-container | container id where response will be loaded
 * data-hide_elements | list of elements (by id) to hide before ajax start, defineds as CSV list
 * data-update_element | element id where to append resultView, if not defined container will be used
 * data-url | REQUIRED| action url
 * data-objectId | element id for scrollto action after ajax call
 * data-autoload | trigger ajax form loading when trigger element is loaded
 * data-append | if param exists, 1=append to the top of update element, 2=append to the bottom of update element, if not exists replace update element content with response, 3=just close modal dialog, 4=replace container element
 * data-modal_size | foundation modal size: tiny small large full, default to tiny
 * data-parent_field | form field class who's value will be changed
 * data-id_field | form field class who's value will be populated by entity id
 * data-for_basic_info | if true, additional functionality for Basic Info will be performed
 * data-reveal_additional_class | class that will allow to customize reveal/modal window
 */
function PopupFormTriggerConfig(formId, callbacks, container, hide_elements, update_element, url, scrollto, append, autoload, modal_size, parent_field, id_field, for_basic_info, hide_close_button, reveal_additional_class){
  this.formId = formId; // unique form id
  this.callbacks = callbacks; // js callback, fired on success
  this.container = container; // container id, response placeholder
  this.hide_elements = hide_elements; // elements ids to hide on ajax call
  this.update_element = update_element; // element id where to load response in case that it is different that container
  this.url = url; // ajax call url
  this.scrollto = scrollto; // scroll to element id on success
  this.flashMessage = null; // flash message to display (type, message)
  this.append = append; // append state: null: replace update element content, 1- top, 2-bottom
  this.autoload = autoload;
  this.modal_size = modal_size;
  this.parent_field = parent_field;
  this.id_field = id_field;
  this.for_basic_info = for_basic_info;
  this.hide_close_button = hide_close_button;
  this.reveal_additional_class = reveal_additional_class; // additional class for reveal/modal window

  this.getFormSelector = () => `#${ this.formId }`;

  this.getFormObject = () => $(`#${ this.formId }`);

  this.getCallBackMethods = () => this.callbacks ? this.callbacks.split(',') : [];

  this.getHideElementsList = () => this.hide_elements ? this.hide_elements.split(',') : [];

  this.getContainerObject = () => $(`#${ this.container }`);

  this.getUpdateObject = () => this.update_element ? $(`#${ this.update_element }`) : this.getContainerObject();

  this.isAutoLoad = () => !!(this.autoload && this.autoload == 1);

  this.isAppendStateTop = () => !!(this.append && this.append == 1);

  this.isAppendStateBottom = () => !!(this.append && this.append == 2);

  this.isCloseState = () => !!(this.append && this.append == 3);

  this.isReplaceState = () => !!(this.append && this.append == 4);

  this.getScrollToObject = () => $(`#${ this.scrollto }`);

  this.getModalSize = () => this.modal_size ? this.modal_size : 'tiny';

  this.getParentField = () => this.parent_field ? $(`.${this.parent_field}`).last() : false;

  this.getIdField = () => this.id_field ? $(`.${this.id_field}`).last() : false;

  this.isForBasicInfo = () => this.for_basic_info;

  this.getRevealAdditionalClass = () => this.reveal_additional_class;
}

function PopupFormModal(trigger_element){
  const AjaxFormId = 'ajaxModalForm';

  this.formConfig = new PopupFormTriggerConfig(AjaxFormId,
      getDataValue(trigger_element, 'callbacks'),
      getDataValue(trigger_element, 'container'),
      getDataValue(trigger_element, 'hide_elements'),
      getDataValue(trigger_element, 'update_element'),
      getDataValue(trigger_element, 'url'),
      getDataValue(trigger_element, 'scrollto'),
      getDataValue(trigger_element, 'append'),
      getDataValue(trigger_element, 'autoload'),
      getDataValue(trigger_element, 'modal_size'),
      getDataValue(trigger_element, 'parent_field'),
      getDataValue(trigger_element, 'id_field'),
      getDataValue(trigger_element, 'for_basic_info'),
      getDataValue(trigger_element, 'hide_close_button'),
      getDataValue(trigger_element, 'reveal_additional_class'));

  this.triggerElement = trigger_element;
  this.state = 'active';
  this.modal_template = $(`<div 
                                 class="${ this.formConfig.getModalSize() } reveal v2 modal modal--basic ${ this.formConfig.getRevealAdditionalClass() }" id="ajaxModalWithJs"
                                 data-reveal data-multiple-opened role="dialog" data-close-on-click="false" data-close-on-esc="false"
                                 data-resize="ajaxModalWithJs"
                             >
                                 <button class="js-close-button close-button modal__close-btn"  aria-label="Close reveal" type="button">
                                     <span class="reveal--close" aria-hidden="true">&times;</span>
                                 </button>
                                 <div id="ajaxModalContentWraper"></div>
                             </div>`);
  if (this.formConfig.hide_close_button) {
    this.modal_template.find('.js-close-button.close-button').hide();
  }
  this.modal_object = null;
  this.displayFlashMessage = true;
  this.start = function (){
    const _this = this;

    $.ajax({
      type: 'GET',
      url: _this.formConfig.url,
      dataType: 'JSON',
      contentType: false,
      processData: false,

      statusCode: {
        406: function (result){
          FlashMessage.showFlashMessages(result.responseText, 'danger');
        }
      },
      success: function (data){
        if (data.success){
          if (_this.modal_object){
            _this.modal_object.remove();
          }
          if (data.state === 'end'){
            _this.formConfig.getContainerObject().replaceWith(data.responseView);
            return;
          }
          $('body').append(_this.modal_template);
          _this.modal_object = $('#ajaxModalWithJs');
          _this.modal_object.foundation();
          _this.modal_object.foundation('open');

          if (_this.modal_object.find('#ajaxModalContentWraper').length > 0){
            // automatic js modal
            _this.modal_object.find('#ajaxModalContentWraper').html(data.responseView);
          } else {
            // manual modal
            _this.modal_object.html(data.responseView);
          }
          _this.showGrecaptcha(_this.modal_object);
          const form = _this.modal_object.find('form');
          $(form).attr('id', AjaxFormId);
          reinitTooltip();
        } else {
          _this.triggerElement.show();
        }

        if (data.hasOwnProperty('flashMessage')){
          _this.flashMessage = data.flashMessage;
        }
      },
      complete: function (){
        _this.triggerElement.removeClass('avoid-clicks');
        _this.showFlashMessage();
        _this.modal_object.find('.dropdown').foundation();
      },
      error: function (){
        _this.showFlashMessage();
      }
    });
  };
  this.showFlashMessage = function (){
    if (this.displayFlashMessage){
      if (this.flashMessage){
        FlashMessage.showShortMessage(this.flashMessage);
      }
    }
  };
  this.showHiddenElements = function (){
    const elementsList = this.formConfig.getHideElementsList();
    for (let i = 0; i < elementsList.length; i++){
      $('#' + elementsList[i]).show();
    }
  };
  let __this = this;
  this.showGrecaptcha = function (wrapper){
    let target = wrapper.find('.recaptcha');
    if (target.length) {
      grecaptcha.ready(function() {
        grecaptcha.render(target.get(0), {
          'sitekey': target.data('key'),
          'size': 'invisible',
          'callback': function (response){
            target.find('[name=g-recaptcha-response]').val(response);
            __this.submittForm(wrapper.find('.js-ajax-popup-form-submit'));
          }
        });
      });
    }
  };
  this.scrolltobox = function (scrollToId){
    const _this = this;
    const element = _this.formConfig.getScrollToObject();
    if (element && element.length){
      scrollToElement(element);
    }
  };
  this.hideHiddenElements = function (){
    const elementsList = this.formConfig.getHideElementsList();
    for (let i = 0; i < elementsList.length; i++){
      $('#' + elementsList[i]).hide();
    }
  };
  this.submit = function (submitButton){
    const submitConfig = new AjaxSubmitPopupFormConfig($(submitButton).data('confirmationtitle'), $(submitButton).data('confirmationmessage'));

    if (typeof __this.formConfig.getFormObject().attr('action') !== 'undefined' && __this.formConfig.url !== __this.formConfig.getFormObject().attr('action')){
      __this.formConfig.url = __this.formConfig.getFormObject().attr('action');
    }

    if (submitConfig.hasConfirmation()){
      const modal = `<div class="v2 reveal modal modal--confirm" id="foundation_confirmation" data-multiple-opened="true"> 
                            <button class=" close-button modal__close-btn js-close-confirmation-button" aria-label="Close reveal" type="button" data-close> 
                            <span class="reveal--close" aria-hidden="true">×</span> 
                            </button>
                                <button class="modal__icon modal__icon--confirm"> 
                                <svg class="icon icon-svg--question-circle"><use xlink:href="#svg--question-circle"></use></svg> 
                            </button>
                            <div class="modal__heading">${ submitConfig.getTitle() }</div>
                            <div class="modal__content modal__content--confirm">${ submitConfig.confirmMessage }</div>
                            <div class="modal__actions modal__actions--confirm"> 
                                <div class="btn-group btn-group--rtl">
                                    <button class="v2 btn btn--outline btn--md margin-right js-close-confirmation-button" data-close>Cancel</button> 
                                    <button class="v2 btn btn--primary btn--md js-confirm-btn" id="yes_confirmation">${ submitConfig.getYesText() }</button>
                                </div>
                             </div>
                        </div>`;
      $('body').append(modal);
      const confirmationDialog = new Foundation.Reveal($('#foundation_confirmation'));
      confirmationDialog.open();
      const modal_object = $('#foundation_confirmation');

      $('#yes_confirmation').on('click', function (e){
        // close and REMOVE FROM DOM to avoid multiple binding
        confirmationDialog.close();
        modal_object.remove();
        // calling the function to process
        if (__this.formConfig.getFormObject().find('.recaptcha').length) {
          grecaptcha.execute();
        } else {
          __this.submittForm($(submitButton));
        }
      });
      $('.js-close-confirmation-button').on('click', function (e){
        // close and REMOVE FROM DOM to avoid multiple binding
        confirmationDialog.destroy();
        modal_object.remove();
      });
    } else {
      if (__this.formConfig.getFormObject().find('.recaptcha').length) {
        grecaptcha.execute();
      } else {
        __this.submittForm($(submitButton));
      }
    }
  };
  this.ajaxCall = function (Data){
    const self = this;
    $.ajax({
      type: 'POST',
      url: self.formConfig.url,
      data: Data,
      dataType: 'JSON',
      contentType: false,
      processData: false,
      beforeSend: function (){
        blockElement(self.formConfig.getFormObject());
      },
      statusCode: {
        406: function (result){
          FlashMessage.showFlashMessages(result.responseText, 'danger');
        }
      },
      success: function (data){
        if (data.reload){
          self.displayFlashMessage = false;
          location.reload();
        } else if (data.goToUrl){
          self.displayFlashMessage = false;
          window.location.href = data.goToUrl;
        } else {
          const responseViewHtml = data.responseView;

          if (data.success){
            if (data.state == 'active'){
              // multi forms case display additional form step
              if (self.modal_object.find('#ajaxModalContentWraper').length > 0){
                self.modal_object.find('#ajaxModalContentWraper').html(responseViewHtml);
              } else {
                self.modal_object.html(responseViewHtml);
              }
              const form = self.modal_object.find('form');
              $(form).attr('id', AjaxFormId);
              self.showGrecaptcha(self.modal_object);
            } else if (data.state == 'end'){
              self.modal_object.foundation('close');
              if (self.formConfig.getParentField()){
                self.formConfig.getParentField().val(data.value);
                self.formConfig.getParentField().trigger("autocomplete:readonly");
              }
              if (self.formConfig.isAppendStateTop()){
                self.formConfig.getUpdateObject().prepend(responseViewHtml);
              } else if (self.formConfig.isAppendStateBottom()){
                self.formConfig.getUpdateObject().append(responseViewHtml);
              } else if (self.formConfig.isReplaceState()){
                self.formConfig.getUpdateObject().replaceWith(responseViewHtml);
              } else if (!self.formConfig.isCloseState()){
                self.formConfig.getUpdateObject().html(responseViewHtml);
              }
              if (self.formConfig.getIdField()){
                self.formConfig.getIdField().val(data.id);
              }
              if (self.formConfig.isForBasicInfo()){
                self.formConfig.getIdField().removeClass('institution-id');
                self.formConfig.getParentField().prop('readonly', true);
                self.formConfig.getParentField().removeClass('institution-name');
                $(`.js-affiliations-add`).removeAttr('disabled', 'disabled').removeClass('button--disabled');
              }
              self.triggerElement.show();
              self.modal_object.remove();
            }
            self.showHiddenElements();
            reinitTooltip();
          }
        }
        const callBackMethods = self.formConfig.getCallBackMethods();
        for (let i = 0; i < callBackMethods.length; i++){
          const func = window[callBackMethods[i]];
          const retCallback = func(data);
        }

        if (data.objectId){
          self.formConfig.scrollto = data.objectId;
        }

        if (data.hasOwnProperty('flashMessage')){
          self.flashMessage = data.flashMessage;
        }
        if (data.hasOwnProperty('modalResponseView') && data.modalResponseView) {
            openFoundationMessage(data.modalResponseView, 'new-modal-confirm', '');
        }
      },
      complete: function (response){
        unblockElement(self.formConfig.getFormObject());
        self.showFlashMessage();
        self.scrolltobox();
      },
      error: function (){
        self.showFlashMessage();
      }

    });
  };
  this.submittForm = function (submitButton){
    const _this = this;
    const Data = new FormData();

    Data.append(submitButton.attr('name'), '');
    $.each($('input[type="file"]'), function (i, file_elem){
      Data.append($(file_elem).attr('name'), file_elem.files[0]);
    });

    $.each(_this.formConfig.getFormObject().serializeArray(), function (i, elem){
      Data.append(elem.name, elem.value);
    });
    _this.ajaxCall(Data);
    _this.hideHiddenElements();
  };
}

$(document).ready(function (){
  $(document).on('click', '#js-ajax-popup-form-submit', function (e){
    e.preventDefault();
    PopupFormModalInstance.submit(e.target);
  });
  $(document).on('click', '.js-ajax-popup-form-submit', function (e){
    e.preventDefault();
    PopupFormModalInstance.submit(e.target);
  });

  /**
   * open popup form
   */
  $(document).on('click', '.js-popup-form', function (e){
    e.preventDefault();
    this.classList.add('avoid-clicks');
    PopupFormModalInstance = new PopupFormModal($(this));
    PopupFormModalInstance.start();
  });
});

/**
 * Function to open Foundation reveal for specific url
 *
 * @param {String} url
 */
function ajaxPopupDialog(url, buttonObject){
  if (typeof buttonObject !== 'undefined'){
    PopupFormModals = new PopupFormModal(buttonObject);
  }

  const callback = buttonObject.data('callback');

  if ($('#ajaxModalWithJs')){
    $('#ajaxModalWithJs').remove();
  }

  const modal = $(`<div 
                        class="reveal v2 modal modal--basic" 
                        id="ajaxModalWithJs"  
                        data-reveal 
                        data-multiple-opened 
                        role="dialog" 
                        data-close-on-click="false" 
                        data-close-on-esc="false"  
                        data-resize="ajaxModalWithJs"
                    > 
                        <button 
                            class="js-close-button 
                            close-button modal__close-btn"  
                            aria-label="Close reveal" 
                            type="button"
                        > 
                            <span class="reveal--close" aria-hidden="true">&times;</span> 
                        </button> 
                        <div id="ajaxModalContentWraper"></div> 
                    </div>`);

  $('body').append(modal);
  $('#ajaxModalWithJs').foundation();

  $.ajax(url).done(function (response){
    if (response.hasOwnProperty('directReturn') && response.directReturn){
      PopupFormModals.handleResponse(response, buttonObject);
      PopupFormModals = null;
      if ($('#ajaxModalWithJs')){
        $('#ajaxModalWithJs').remove();
      }
    } else if (response.responseView){
      $('#ajaxModalContentWraper').html(response.responseView);
      if ($('#ajaxModalContentWraper').text().trim() != ''){
        $('#ajaxModalWithJs').foundation('open');
      }
    } else if (response.hasOwnProperty('flashMessage') && response.flashMessage){
      const flashMessage = response.flashMessage;
      FlashMessage.displayFlashMessages(flashMessage);
    }

    if (typeof callback !== 'undefined'){
      const func = window[callback];
      func(response);
    }
    buttonObject.removeClass('avoid-clicks');
  }).fail(function (jqXHR, textStatus){
    const response = jqXHR.responseJSON;
    if ((response.success == 'false' || response.success == false) && response.flashMessage){
      FlashMessage.showFlashMessages(response.flashMessage.message, response.flashMessage.messageType);
    } else if (response.success == 'false' || response.success == false){
      displayException(response.message);
    }
    buttonObject.removeClass('avoid-clicks');
  });
}

function ajaxPopupDialogForResponseView(responseView){
  if ($('#ajaxModalWithJs')){
    $('#ajaxModalWithJs').remove();
  }

  const modal = $(`<div 
                        class="small reveal" 
                        id="ajaxModalWithJs" 
                        data-reveal 
                        data-multiple-opened 
                        role="dialog" 
                        data-close-on-click="false" 
                        data-close-on-esc="false" 
                        data-resize="ajaxModalWithJs"
                    > 
                        <button 
                            class="js-close-button 
                            close-button"  
                            aria-label="Close reveal" 
                            type="button"
                        > 
                            <span class="reveal--close" aria-hidden="true">&times;</span> 
                        </button> 
                        <div id="ajaxModalContentWraper"></div> 
                    </div>`);

  $('body').append(modal);
  $('#ajaxModalWithJs').foundation();

  $('#ajaxModalContentWraper').html(responseView);
  if ($('#ajaxModalContentWraper').text().trim() != ''){
    $('#ajaxModalWithJs').foundation('open');
  }
}
